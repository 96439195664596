import React, { useEffect, useState, } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import "../../assets/css/Careersite.css"
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {
    Card,
    Stack,
    Button,
    Container,
    Typography,
    ListItemIcon,
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    DialogContent,
    Box,
    Chip,
    Avatar,
    useMediaQuery,
    CardContent,
    IconButton,
    InputAdornment,
    Pagination
} from '@mui/material';
import WorkIcon from '@mui/icons-material/Work';
import HomeIcon from '@mui/icons-material/Home';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import PersonIcon from '@mui/icons-material/Person';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SearchIcon from '@mui/icons-material/Search';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import Carousel from 'react-material-ui-carousel'
import FileUpload from 'react-material-file-upload';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
// eslint-disable-next-line import/no-unresolved
import { useGetTestimonialsQuery } from '../../redux/services/settings/TestimonialService';
import ReactQuill from 'react-quill';
import { useGetJobListQuery, useGetJobsByCompanyQuery } from '../../redux/services/jobs/JobListService';
import { companyInfoGET } from '../../utils/apis';
import { showToast } from '../../utils/toast';
import { useGetCompanyInfoQuery } from '../../redux/services/settings/CareerSiteService';
import { useDepartmentGetQuery } from '../../redux/services/settings/DepartmentService';
import { useGetAddressesQuery } from '../../redux/services/settings/AddressesService';

function CareerSiteDescription() {
    const { id } = useParams()
    // console.log("company id", id)
    const navigate = useNavigate()
    const [companyInfo, setCompanyInfo] = useState({})
    const { data, isLoading, refetch } = useGetCompanyInfoQuery();
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredJobs, setFilteredJobs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 8;

    useEffect(() => {
        refetch()
        const fetchData = async () => {
            try {
                const response = await companyInfoGET(id);
                // console.log("companie info data", response.data.company)
                setCompanyInfo(response.data.company)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [id, refetch])
    const { data: JobListByCompany, refetch: JobListByCompanyRefetch } = useGetJobsByCompanyQuery(id);
    const { data: jobList, refetch: JobListRefetch } = useGetJobListQuery();
    useEffect(() => {
        if (id) {
            JobListByCompanyRefetch()
            setValue("2");
        }
        else {
            JobListRefetch()
        }
    }, [id, JobListByCompanyRefetch, JobListRefetch])

    const { data: testimonialData, refetch: testimonialDataRefetch } = useGetTestimonialsQuery();
    useEffect(() => {
        testimonialDataRefetch()
    }, [testimonialDataRefetch])

    const { data: departmentData, refetch: departmentDataRefetch } = useDepartmentGetQuery(id);
    useEffect(() => {
        departmentDataRefetch();
    }, [departmentDataRefetch]);

    const { data: addressesData, refetch: addressesDataRefetch } = useGetAddressesQuery()
    useEffect(() => {
        addressesDataRefetch()
    }, [addressesDataRefetch])

    const [AboutData, setAboutData] = useState({
        institute_name: '',
        institute_logo: '',
        institute_description: '',
        institute_address: '',
        institute_landmark: '',
        institute_city: '',
        institute_state: '',
        institute_country: '',
        institute_tags: []
    })
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (data?.company) {
            setAboutData({
                institute_name: data?.company?.name,
                institute_logo: data?.company?.logo,
                institute_description: data?.company?.description,
                institute_address: data?.company?.address,
                institute_landmark: data?.company?.landmark,
                institute_city: data?.company?.city_name,
                institute_state: data?.company?.state_name,
                institute_country: data?.company?.country_name,
                institute_tags: data?.company?.tag
            })
        }
        if (data?.code !== 200) {
            showToast("error", "Error fetching the Data")
        }
    }, [data])

    // console.log(AboutData.institute_tags)

    const handleClick = () => {
        navigate(-1);
    };

    const handleSearch = () => {
        if (searchTerm) {
            const filtered = JobListByCompany.filter(job =>
                job.title.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredJobs(filtered);
        } else {
            setFilteredJobs(JobListByCompany);
        }
    };

    useEffect(() => {
        setFilteredJobs(JobListByCompany);
    }, [JobListByCompany]);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredJobs ? filteredJobs.slice(indexOfFirstItem, indexOfLastItem) : [];

    return (
        <div>
            <Box sx={{ minHeight: '200px', display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Box sx={{ padding: '20px' }}><img src={companyInfo.logo} alt='company logo' width='100px' height='100px' />
                    </Box>
                    <Typography sx={{ fontSize: '26px', fontWeight: 700, color: '#1976d2' }}>{companyInfo.name}</Typography>
                </Stack>
                <IconButton
                    sx={{ paddingRight: '20px', fontSize: '26px', fontWeight: 700, color: '#1976d2', cursor: 'pointer' }}
                    onClick={handleClick}
                >
                    <HomeIcon fontSize="large" />
                </IconButton>
            </Box>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', display: "flex", marginLeft: "5%", marginTop: "2%" }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example" sx={{ display: "flex", }}>
                        <Tab label="About" value="1" sx={{ fontSize: "20px" }} />
                        <Tab label="Available Jobs" value="2" sx={{ fontSize: "20px" }} />
                    </TabList>
                </Box>
                <TabPanel value="1" sx={{ display: "flex", justifyContent: "center" }}>
                    <Card className='about-card' sx={{ width: "80%", minHeight: '200px', padding: '20px' }}>
                        <Stack gap={2} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Typography color='#1976d2'>
                                Name:
                            </Typography>
                            <Typography sx={{ fontSize: '18px' }}>{companyInfo?.name}</Typography>
                        </Stack>
                        <Stack gap={2} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Typography color='#1976d2'>
                                Domain:
                            </Typography>
                            <Typography sx={{ fontSize: '18px' }}>{companyInfo?.domain}</Typography>
                        </Stack>
                        <Stack gap={2} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Typography color='#1976d2'>
                                Website Link:
                            </Typography>
                            <a href={companyInfo?.website} target="_blank" rel="noopener noreferrer" >
                                <Typography sx={{ fontSize: '18px' }}>{companyInfo?.website}</Typography>
                            </a>
                        </Stack>
                        <Stack gap={2} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Typography color='#1976d2'>
                                Address:
                            </Typography>
                            <Typography sx={{ fontSize: '18px' }}>{companyInfo?.address}</Typography>
                        </Stack>
                        <Stack gap={2} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Typography color='#1976d2'>
                                City:
                            </Typography>
                            <Typography sx={{ fontSize: '18px' }}>{companyInfo?.city_name}</Typography>
                        </Stack>
                        <Stack gap={2} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Typography color='#1976d2'>
                                Description:
                            </Typography>
                            <Typography sx={{ fontSize: '18px' }}>{companyInfo?.description}</Typography>
                        </Stack>
                    </Card>
                </TabPanel>
                <TabPanel value="2" sx={{ backgroundColor: "#f9fafb" }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
                        <TextField
                            variant="outlined"
                            placeholder="Job title, keywords..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            sx={{ width: '60%' }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Button
                            variant="contained"
                            onClick={handleSearch}
                            sx={{
                                ml: 2,
                                backgroundColor: 'primary.main',
                                color: 'primary.contrastText',
                                '&:hover': {
                                    backgroundColor: 'primary.dark',
                                },
                                display: 'flex',
                                alignItems: 'center',
                                padding: '10px 20px',
                                borderRadius: '5px',
                                fontSize: '16px',
                                fontWeight: 'bold',
                            }}
                        >
                            <SearchIcon sx={{ mr: 1 }} />
                            Search Job
                        </Button>
                    </Box>
                    <Grid container spacing={3} justifyContent="center">
                        {currentItems && currentItems.length > 0 ? (
                            currentItems?.map((item) => (
                                <Grid item xs={12} sm={6} md={3} key={item.id}>
                                    <Card 
                                        sx={{
                                            borderRadius: '14px',
                                            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                                            padding: '20px',
                                            cursor: 'pointer',
                                            transition: 'transform 0.3s, box-shadow 0.3s',
                                            '&:hover': {
                                                transform: 'scale(1.05)',
                                                boxShadow: '0 8px 30px rgba(0, 0, 0, 0.2)',
                                            },
                                        }} onClick={() => navigate(`/jobs/Careers/${item?.id}/${encodeURIComponent(item?.title)}`, { state: { logo: companyInfo.logo } })}>
                                        <CardContent>
                                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                                <WorkIcon fontSize="large" />
                                            </Stack>
                                            <Typography
                                                variant="h6"
                                                sx={{
                                                    marginTop: '10px',
                                                    fontWeight: 'bold',
                                                    '&:hover': {
                                                        textDecoration: 'underline',
                                                    },
                                                }}
                                            >
                                                {item.title}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary" sx={{ marginBottom: '10px' }}>
                                                {departmentData?.data?.find(e => e.id === +item.department)?.name}
                                            </Typography>
                                            <Stack direction="row" alignItems="center" spacing={1}>
                                                <LocationOnIcon fontSize="small" />
                                                <Typography variant="body2" color="text.secondary">
                                                    {item.address_name}
                                                </Typography>
                                            </Stack>
                                            <Typography variant="body2" color="text.secondary" sx={{ marginTop: '10px' }}>
                                                Posted at: {item.created.split('T')[0]}
                                            </Typography>
                                            <Box sx={{ borderTop: '1px solid #e0e0e0', marginTop: '10px', paddingTop: '10px' }}>
                                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                                    <Stack direction="row" alignItems="center" spacing={1}>
                                                        <WorkIcon fontSize="small" />
                                                        <Typography variant="body2" color="text.secondary">
                                                            {item.exp_min} year exp
                                                        </Typography>
                                                    </Stack>
                                                    <Stack direction="row" alignItems="center" spacing={1}>
                                                        <WatchLaterIcon fontSize="small" />
                                                        <Typography variant="body2" color="text.secondary">
                                                            {item.type}
                                                        </Typography>
                                                    </Stack>
                                                </Stack>
                                                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ marginTop: '5px' }}>
                                                    <Stack direction="row" alignItems="center" spacing={1}>
                                                        <CurrencyRupeeIcon fontSize="small" />
                                                        <Typography variant="body2" color="text.secondary">
                                                            {item.salary_min} - {item.salary_max}
                                                        </Typography>
                                                    </Stack>
                                                    <Stack direction="row" alignItems="center" spacing={1}>
                                                        <PersonIcon fontSize="small" />
                                                        <Typography variant="body2" color="text.secondary">
                                                            {item.educations}
                                                        </Typography>
                                                    </Stack>
                                                </Stack>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))
                        ) : (
                            <Typography variant="h6" color="text.secondary" sx={{ marginTop: '20px' }}>
                                No jobs available.
                            </Typography>
                        )}
                    </Grid>
                    {filteredJobs && filteredJobs.length > 0 && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                            <Pagination
                                count={Math.ceil(filteredJobs.length / itemsPerPage)}
                                page={currentPage}
                                onChange={handlePageChange}
                                color="primary"
                            />
                        </Box>
                    )}
                </TabPanel>
            </TabContext>
        </div>
    );
}

export default CareerSiteDescription