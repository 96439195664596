
import React, { useState, useEffect } from 'react';
import { apiGET } from '../utils/apis';
import { Box, Button, Card, CardContent, CardMedia, Chip, Container, Grid, Typography } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CardCarousel from '../components/CardCarousel';
import StatsSection from '../components/StatsSection';
import Navbar from '../components/Navbar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { baseUrl } from '../utils/api';
import ProductHeroLayout from './Views/ProductHeroLayout';

export default function LandingPage() {
    const [products, setProducts] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [stats, setStats] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await apiGET('account/get-all-company/');
                // console.log("companies data", response.data.companies)
                setProducts(response.data.companies);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await apiGET('jobs/latest-jobs/');
                setJobs(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchStats = async () => {
            try {
                const response = await apiGET('jobs/count-list/');
                const statsData = [
                    { label: 'Candidates', value: response.data.total_candidates },
                    { label: 'Jobs', value: response.data.total_jobs },
                    { label: 'Companies', value: response.data.total_companies },
                ];
                setStats(statsData);
            } catch (error) {
                console.error('Error fetching stats:', error);
            }
        };
        fetchStats();
    }, []);

    const backgroundImage = 'https://themewagon.github.io/jobboard/images/hero_1.jpg';

    return (
        <div>
            <Navbar />
            <ProductHeroLayout
                sxBackground={{
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundColor: '#7fc7d9', // Average color of the background image.
                    backgroundPosition: 'center',
                }}
                >
                {/* Increase the network loading priority of the background image. */}
                <img
                    style={{ display: 'none' }}
                    src={backgroundImage}
                    alt="increase priority"
                />
                <Typography color="inherit" align="center" variant="h3" marked="center">
                    The Easiest Way To Get Your Dream Job
                </Typography>
                <Typography
                    color="inherit"
                    align="center"
                    variant="h5"
                    sx={{ mb: 4, mt: { xs: 4, sm: 10 } }}
                >
                    {/* Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cupiditate est, consequuntur perferendis. */}
                </Typography>
                {/* <Button
                    variant="contained"
                    size="large"
                    component="a"
                    href="/premium-themes/onepirate/sign-up/"
                    sx={{ 
                        minWidth: 200,
                        backgroundColor: '#ff3366',
                        '&:hover': {
                            backgroundColor: '#e62e5c',
                        }
                    }}
                >
                    Register
                </Button>
                <Typography variant="body2" color="inherit" sx={{ mt: 2 }}>
                    Discover the experience
                </Typography> */}
            </ProductHeroLayout>
    
                <Box sx={{ 
                    backgroundColor: '#f4f6f8', 
                    padding: '40px 20px', 
                    borderRadius: '8px', 
                    mt: 5, 
                    textAlign: 'center' 
                }}>
                    <Typography variant="h4" component="h2" gutterBottom>
                        Top companies
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
                        <Card sx={{ width: '100%', maxWidth: 1200, p: 3, boxShadow: 'none', backgroundColor: 'transparent' }}>
                            <CardCarousel data={products} />
                        </Card>
                    </Box>
                </Box>
                
                <Box sx={{ width: '100%', mt: 5, overflowY: 'auto', maxHeight: '400px' }}>
                    <StatsSection stats={stats} />
                </Box>
            
                <Box sx={{ width: '100%', mt: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#f4f6f8' }}>
                    <Typography variant="h4" align="center" gutterBottom sx={{ mt: 4 }}>
                        Job Listed
                    </Typography>
                    <Box sx={{ width: '100%', maxWidth: 1200 }}>
                        {jobs.map((job) => (
                            <Card 
                            key={job.id} 
                            sx={{ 
                                mb: 2, 
                                mx: 'auto', 
                                width: '100%', 
                                maxWidth: 1200, 
                                height: 120,
                                position: 'relative',
                                transition: 'transform 0.3s, box-shadow 0.3s', 

                                '&:hover': {
                                    // transform: 'scale(1.05)',
                                    boxShadow: 3,
                                    '&::before': {
                                        height: '100%',
                                    }
                                },

                                '&::before': {
                                    content: '""',
                                    position: 'absolute',
                                    left: 0,
                                    top: 0,
                                    width: '6px',
                                    height: 0,
                                    backgroundColor: '#89ba16',
                                    transition: 'height 0.3s',
                                }
                                }}
                            >
                                <CardContent>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={12} sm={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <img 
                                                src={`${baseUrl}${job.company_logo}`} 
                                                alt={job.company_name} 
                                                style={{ width: '100%', maxWidth: '80px' }} 
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="h6">{job.title}</Typography>
                                            <Typography variant="body2" color="textSecondary">{job.company_name}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={2} sx={{ display: 'flex', alignItems: 'center' }}>
                                            <LocationOnIcon sx={{ mr: 1, color: 'text.secondary' }} />
                                            <Typography variant="body2" color="textSecondary">{job.location}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <Chip 
                                                label={job.type} 
                                                size="small"
                                                sx={{ 
                                                    backgroundColor: job.type === 'Full Time' ? '#89ba16' : '#dc3545', 
                                                    color: 'white', 
                                                    borderRadius: '4px'
                                                }} 
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        ))}
                    </Box>
                </Box>
            <ToastContainer />
        </div>
    );
}
