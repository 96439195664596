import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
    Button, Card, CircularProgress, Box,
    Typography
} from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { experimentalStyled as styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import ReactQuill from 'react-quill';
import { useGetAddresseDetailsQuery } from '../../redux/services/settings/AddressesService';
import { useGetJobeDetailsQuery } from '../../redux/services/jobs/JobServices';

function SingleJobView() {
    const { id } = useParams()
    const location = useLocation();
    const logo = location.state?.logo;
    localStorage.setItem("jobId", id)
    const { data, isLoading, refetch } = useGetJobeDetailsQuery(id);
    // console.log("jobdetails", data)
    const { data: addressData, isLoading: addressIsLoading, refetch: addressRefetch } = useGetAddresseDetailsQuery(data?.location);
    const [jobType, setJobType] = useState()
    const navigate = useNavigate()
    // console.log(data,addressData)


    const mappingEducation = {
        1: "High School",
        2: "Junior College",
        3: "Bachelors",
        4: "Masters"
    }

    useEffect(() => {
        refetch()
    }, [id])

    useEffect(() => {
        if (data?.type === "F") {
            setJobType("Full Time")
        } else {
            setJobType("Part Time")
        }
        // console.log(data?.assesment)
        localStorage.setItem("assesment", data?.assesment)
    }, [data])

    return (
        <>
            <Box sx={{ backgroundColor: "#ffffff", minHeight: "100vh", mt: 5, p: 2 }}>
                <Box className='applicationTop' sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <ArrowBackIcon sx={{ cursor: "pointer" }} color="primary" onClick={() => navigate(-1)} />
                    <Box sx={{
                        width: 120,
                        height: 120,
                        borderRadius: "50%",
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: "#f9fafb",
                        mx: 'auto'
                    }}>
                        <img src={logo} alt='company logo' style={{ width: '100%', height: '100%', borderRadius: '50%' }} />
                    </Box>
                </Box>
                <Card sx={{ mt: 5, backgroundColor: "#f9fafb", p: 2 }}>
                    <Typography variant="h4" align="center" gutterBottom>
                        {data?.title} - {data?.department_name}
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4}>
                            <Box sx={{ border: '1px solid #ccc', p: 2, borderRadius: 2 }}>
                                <Typography variant="h6" color="primary">Department</Typography>
                                <Typography>{data?.department_name}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Box sx={{ border: '1px solid #ccc', p: 2, borderRadius: 2 }}>
                                <Typography variant="h6" color="primary">Type</Typography>
                                <Typography>{data?.type}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Box sx={{ border: '1px solid #ccc', p: 2, borderRadius: 2 }}>
                                <Typography variant="h6" color="primary">Education</Typography>
                                <Typography>{data?.educations}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Box sx={{ border: '1px solid #ccc', p: 2, borderRadius: 2 }}>
                                <Typography variant="h6" color="primary">Major/Speciality</Typography>
                                <Typography>{data?.speciality}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Box sx={{ border: '1px solid #ccc', p: 2, borderRadius: 2 }}>
                                <Typography variant="h6" color="primary">Work Experience</Typography>
                                <Typography>{data?.exp_min}-{data?.exp_max} Years</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Box sx={{ border: '1px solid #ccc', p: 2, borderRadius: 2 }}>
                                <Typography variant="h6" color="primary">Salary Offered</Typography>
                                <Typography>{data?.salary_min}-{data?.salary_max}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Box sx={{ border: '1px solid #ccc', p: 2, borderRadius: 2 }}>
                                <Typography variant="h6" color="primary">Country</Typography>
                                <Typography>{addressData?.country_name}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Box sx={{ border: '1px solid #ccc', p: 2, borderRadius: 2 }}>
                                <Typography variant="h6" color="primary">City</Typography>
                                <Typography>{addressData?.city_name}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Typography variant="h5" align="center" color="primary" gutterBottom sx={{ mt: 3, textDecoration: 'underline' }}>
                        Job Description
                    </Typography>
                    <Card sx={{
                        p: 2,
                        border: "3px solid #ccc ",
                        borderRadius: "20px",
                        height: "15em",
                        overflow: "auto"
                    }}>
                        <div dangerouslySetInnerHTML={{ __html: data?.description }} style={{ height: "10em", border: "none" }} />
                    </Card>
                    <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                        <Button
                            sx={{
                                mb: 2,
                                borderRadius: "20px",
                                width: "150px"
                            }}
                            variant="contained"
                            onClick={() => navigate(`/JobApplication/${data.webform}`)}
                        >
                            Apply
                        </Button>
                    </Box>
                </Card>
            </Box>
        </>
    )
}

export default SingleJobView