import React from 'react';
import { Box, Card, Typography, Button, CardContent, IconButton, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const CardCarousel = ({ data }) => {
    const navigate = useNavigate();
    const containerRef = React.useRef(null);

    const handleClick = (id, name) => {
        navigate(`/jobs/${id}/${name}`);
    };

    const scrollLeft = () => {
        containerRef.current.scrollBy({ left: -containerRef.current.clientWidth, behavior: 'smooth' });
    };

    const scrollRight = () => {
        containerRef.current.scrollBy({ left: containerRef.current.clientWidth, behavior: 'smooth' });
    };

    return (
        <Box sx={{ position: 'relative', maxWidth: '1200px', margin: '0 auto' }}>
            <IconButton
                onClick={scrollLeft}
                sx={{ position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)', zIndex: 1 }}
            >
                <ArrowBackIosIcon />
            </IconButton>
            <Box
                ref={containerRef}
                sx={{
                    display: 'flex',
                    overflowX: 'auto',
                    scrollBehavior: 'smooth',
                    padding: '10px',
                    '&::-webkit-scrollbar': { display: 'none' },
                }}
            >
                {data?.map((item, index) => (
                    <Box 
                        key={index} 
                        sx={{ 
                            flex: '0 0 auto', 
                            width: { xs: '80%', sm: '40%', md: '25%', lg: '20%' }, 
                            padding: '0 10px' 
                        }}
                    >
                        <Card
                            sx={{
                                height: '100%',
                                maxHeight: '270px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                border: '2px solid #F4F4F4',
                                borderRadius: '10px',
                                padding: '20px',
                                textAlign: 'center',
                                margin: '10px',
                                backgroundColor: 'transparent',
                                transition: 'transform 0.3s, box-shadow 0.3s',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                                    borderColor: '#1D4ED8',
                                    backgroundColor: 'white',
                                },
                            }}
                        >
                            <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <Box>
                                    <img src={item.logo} width='80px' height='80px' alt={`${item.name} logo`} style={{ margin: '0 auto' }} />
                                    <Typography variant="h6" sx={{ marginTop: '10px' }}>{item.name}</Typography>
                                    <Link 
                                        href={item.website} 
                                        target="_blank" 
                                        rel="noopener" 
                                        sx={{ 
                                            marginTop: '10px', 
                                            color: '#1D4ED8', 
                                            wordBreak: 'break-word' // Ensure long links wrap to a new line
                                        }}
                                    >
                                        {item.website}
                                    </Link>
                                </Box>
                                <Button 
                                    variant='contained' 
                                    onClick={() => handleClick(item.id, item.name)} 
                                    sx={{ 
                                        marginTop: '20px', 
                                        backgroundColor: '#E0E7FF', 
                                        color: '#1D4ED8', 
                                        borderRadius: '20px',
                                        '&:hover': {
                                            backgroundColor: '#E0E7FF', // Same as the default background color
                                        }
                                    }}
                                >
                                    View jobs
                                </Button>
                            </CardContent>
                        </Card>
                    </Box>
                ))}
            </Box>
            <IconButton
                onClick={scrollRight}
                sx={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)', zIndex: 1 }}
            >
                <ArrowForwardIosIcon />
            </IconButton>
        </Box>
    );
};

export default CardCarousel;