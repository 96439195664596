// NavbarMui.js

import React from 'react';
import { AppBar, Toolbar, Typography, Button, TextField, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom'; // If you're using React Router
import edjobster09 from "../assets/images/edjobster-09.png"

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
    },
    link: {
        textDecoration: 'none',
        color: 'white',
    },
}));

const Navbar = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <AppBar position="static" sx={{ background: '#f4f6f8',boxShadow:'none' }}>
                <Toolbar>
                    <Typography variant="h6" className={classes.title}>
                        <img
                            style={{ width: "13%", margin: "0" }}
                            src={edjobster09}
                            alt="logo" />
                    </Typography>

                    {/* <TextField
                        id="outlined-basic"
                        // onChange={inputHandler}
                        variant="outlined"
                        fullWidth
                        label="Search"
                        InputProps={{
                            endAdornment: (
                                <IconButton  edge="end">
                                    <SearchIcon />
                                </IconButton>
                            ),
                        }}
                        sx={{
                            width:'30%',
                            borderRadius:'8px',
                            '& .MuiInputBase-input.MuiOutlinedInput-input': {
                                padding: '14px 14px',
                            },
                        }}
                    /> */}
                </Toolbar>
            </AppBar>
        </div>
    );
};

export default Navbar;
